import ReactDOM from 'react-dom';
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Alert, Avatar, Backdrop, Box, Button, CircularProgress, Collapse, createTheme, CssBaseline, Divider, Grid, IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText, Paper, SvgIcon, TextField, ThemeProvider, Typography } from '@mui/material';
import { Visibility, VisibilityOff, ErrorOutlined, CheckCircleOutlined } from '@mui/icons-material';
import _ from 'lodash';
import Cookies from 'js-cookie';
import Auth0 from 'auth0-js';
import { vsprintf } from './vsprintf';

const MicrosoftIcon = (props) => (<SvgIcon xmlns='http://www.w3.org/2000/svg' {...props}><g fill='none' fillRule='evenodd'><path fill='#F25022' d='M0 0h9.474v9.474H0z' /><path fill='#00A4EF' d='M0 10.526h9.474V20H0z' /><path fill='#7FBA00' d='M10.526 0H20v9.474h-9.474z' /><path fill='#FFB900' d='M10.526 10.526H20V20h-9.474z' /></g></SvgIcon>);

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright � '}
            <Link color="inherit" href="https://stackadvisors.com/">
                Stack Advisors, LLC.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

const useIsMounted = () => {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return () => isMountedRef.current;
};

const useDebounce = (cb, delay, options) => {
    const inputsRef = useRef(cb);
    const isMounted = useIsMounted();

    useEffect(() => {
        inputsRef.current = { cb, delay };
    });

    return useCallback(
        _.debounce(
            (...args) => {
                // don't execute callback, if (1) component in the meanwhile has been unmounted or (2) delay has changed
                if (inputsRef.current.delay === delay && isMounted()) {
                    inputsRef.current.cb(...args);
                }
            },
            delay,
            options
        ),
        [delay, _.debounce]
    );
}

const AlertIcon = ({ alert }) => {
    switch (alert.severity) {
        case 'success':
            return <CheckCircleOutlined />;
        case 'error':
            return <ErrorOutlined />;
    }
}

const _vsprintf = (format, args) => {
    try {
        if (args && args.length > 0) {
            return vsprintf(format, args);
        } else {
            return format;
        }
    } catch (e) {
        console.error({ format, args });
        throw e;
    }
};

const AlertList = ({ alerts, level = 0 }) => {
    const color = (alert) => {
        switch (alert.severity) {
            case 'success':
                return 'success.main';
            case 'error':
                return 'error.main'
        }
    };

    return alerts && alerts.length > 0 ? (
        <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {alerts.map((alert, index) => (
                <Fragment key={index}>
                    <ListItem sx={{ pl: level * 4 + 2, pt: 0, pb: 0 }}>
                        <ListItemIcon sx={{ minWidth: t => t.spacing(4) }}><AlertIcon alert={alert} /></ListItemIcon>
                        {alert.text ? (
                            <ListItemText primary={_vsprintf(alert.text, alert.args ?? [])} sx={{ color: color(alert) }} />
                        ) : null}
                    </ListItem>
                    {alert.alerts && alert.alerts.length > 0 ? (
                        <AlertList alerts={alert.alerts} level={level + 1} />
                    ) : null}
                </Fragment>
            ))}
        </List>
    ) : null;
};

const SignInWithAzureAdButton = ({ onClick }) => {
    return (
        <Button onClick={onClick} fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }} startIcon={<MicrosoftIcon />}>
            Continue with Microsoft Account
        </Button>
    );
}

const SignIn = ({ options, config, client, webApi, setMode }) => {
    // look up database connection
    let databaseConnection = null;
    if (client && client.strategies && client.strategies[0] && client.strategies[0].connections && client.strategies[0].connections[0]) {
        databaseConnection = client.strategies[0].connections[0].name;
    }

    const [connection, setConnection] = useState(null);
    const [alert, setAlert] = useState(null);
    const [username, setUsername] = useState({ value: '', visible: true, alerts: null });
    const [password, setPassword] = useState({ value: '', visible: false, alerts: null });
    const [ready, setReady] = useState(0);

    // render the captcha when available
    const captchaElementRef = useRef(null);
    const captchaElementPrevRef = useRef(null);
    const captchaRef = useRef(null);
    const webApiPrevRef = useRef(null);
    useLayoutEffect(() => {
        if (webApi && captchaElementRef.current) {
            if (webApi !== webApiPrevRef.current || captchaElementRef.current !== captchaElementPrevRef.current) {
                captchaRef.current = webApi.renderCaptcha(captchaElementRef.current);
                webApiPrevRef.current = webApi;
                captchaElementPrevRef.current = captchaElementRef.current;
            }
        }
    }, [webApi, captchaElementRef.current]);

    // check if the given username is a B2C user
    const getUserConnectionAsync = async (username) => {
        if (username) {
            const path = new URL(`api/users/${username.toLowerCase()}`, options.auth0_web_url).toString();
            const userResponse = await fetch(path, { mode: 'cors' });
            if (userResponse.ok) {
                const user = await userResponse.json();
                if (user.username.toLowerCase() === username.toLowerCase()) {
                    if (user.provider === "auth0") {
                        return databaseConnection;
                    } else if (user.provider === "azureadb2c") {
                        return "azureadb2c";
                    }
                }
            }
        }

        return databaseConnection;
    }

    // resolve the proper connection after the username is changed
    const resolveConnectionAsync = async (username) => setConnection(await getUserConnectionAsync(username));
    const resolveConnectionWithDelayAsync = useDebounce(async (username) => await resolveConnectionAsync(username), 500, { leading: false, trailing: true });
    useEffect(() => resolveConnectionWithDelayAsync(username.value), []);
    useEffect(() => resolveConnectionWithDelayAsync(username.value), [username.value]);

    const setAlertError = (text) => {
        if (text) {
            setAlert({ text: text, severity: 'error' });
        } else {
            setAlert(null);
        }
    };

    const setAlertInfo = (text) => {
        if (text) {
            setAlert({ text: text, severity: 'info' });
        } else {
            setAlert(null);
        }
    };

    const handleError = (error) => {
        if (error.toString()) {
            setAlertError(error.toString());
        }
    };

    const rulesToAlerts = (rules) => {
        return rules && rules.length > 0 ? rules.map(rule => ({
            severity: rule.verified ? 'success' : 'error',
            text: rule.message,
            args: rule.format,
            alerts: rulesToAlerts(rule.items),
        })) : null;
    };

    const handlePasswordStrengthError = (error) => {
        setPassword({
            ...password,
            alerts: rulesToAlerts(error.description.rules)
        });
    };

    const handleWebApiError = (error) => {
        if (error) {
            if (error.name === 'ValidationError' && error.description) {
                setAlertError(error.description);
            } else if (error.name === 'BadRequestError' && error.description) {
                setAlertError(error.description);
            } else if (error.name === 'PasswordStrengthError' && error.code === 'invalid_password') {
                handlePasswordStrengthError(error);
            } else if (error.description) {
                setAlertError(error.description);
            } else if (error.name) {
                setAlertError(error.name);
            }
        }
    };

    const onUsernameChange = (e) => {
        setUsername({ ...username, value: e.target.value, alerts: null })
    };

    const onUsernameBlur = (e) => {
        resolveConnectionWithDelayAsync(username.value);
    };

    const showUsernameField = () => {
        return true
    };

    const showUsernameText = () => {
        return username.visible;
    }

    const onShowUsernameClick = () => {
        setUsername({ ...username, visible: !username.visible });
    }

    const onPasswordChange = (e) => {
        setPassword({ ...password, value: e.target.value, alerts: null })
    };

    const showPasswordField = () => {
        return connection === databaseConnection && !!username.value;
    };

    const showPasswordText = () => {
        return password.visible;
    };

    const onShowPasswordClick = () => {
        setPassword({ ...password, visible: !password.visible });
    }

    // can the user press continue
    const canContinue = () => {
        if (connection === databaseConnection && !!username.value && !!password.value) {
            return true;
        } else if (connection === 'azureadb2c' && !!username.value) {
            return true;
        } else {
            return false;
        }
    };

    // when the user presses continue
    const onContinue = async () => {

        // ensure connection is resolved
        await resolveConnectionAsync(username.value);

        // check new state
        if (canContinue() === false || ready > 0) {
            return;
        }

        // save last logon method
        Cookies.set('last_login_method', '');

        setReady(r => r + 1);

        try {
            if (connection === databaseConnection) {
                webApi.login({
                    realm: connection,
                    username: username.value,
                    password: password.value,
                    captcha: captchaRef.current?.getValue()
                }, function (error, response) {
                    if (error) {
                        handleWebApiError(error);
                    } else {
                        console.log(response);
                    }

                    setReady(r => r - 1);
                });
            } else {
                webApi.authorize({
                    connection: connection,
                    login_hint: username.value,
                }, function (error, response) {
                    if (error) {
                        handleWebApiError(error);
                    }

                    setReady(r => r - 1);
                });
            }
        } catch (err) {
            handleError(err);
            setReady(r => r - 1);
        }
    };

    const onContinueAzureAd = (e) => {
        e.preventDefault();

        // save last logon method
        Cookies.set('last_login_method', 'azuread');

        setReady(r => r + 1);

        try {
            webApi.authorize({
                connection: 'azuread',
            }, function (error, response) {
                if (error) {
                    handleWebApiError(error);
                }

                setReady(r => r - 1);
            });

        } catch (err) {
            handleError(err);
            setReady(r => r - 1);
        }
    };

    const onForget = async (e) => {
        e.preventDefault();

        // ensure connection is resolved
        await resolveConnectionAsync(username.value);
        if (connection !== databaseConnection) {
            return;
        }

        if (!username.value) {
            setAlertError('Enter your email address to recover your password.');
            return;
        }

        setReady(r => r + 1);

        try {
            webApi.changePassword({
                connection: databaseConnection,
                email: username.value
            }, function (error, response) {
                if (error) {
                    handleWebApiError(error);
                } else {
                    setAlertInfo(response);
                }

                setReady(r => r - 1);
            });
        } catch (err) {
            handleError(err);
            setReady(r => r - 1);
        }
    };

    const onSignUp = async (e) => {
        setMode('signup');
    };

    // logic to render the internal login section
    const renderInternalSection = () => (
        <>
            <Collapse in={showUsernameField()}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    label="Email Address"
                    type={showUsernameText() ? 'email' : 'password'}
                    autoComplete="email"
                    autoFocus
                    value={username.value}
                    onChange={onUsernameChange}
                    onKeyDown={async (ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            await onContinue();
                        }

                    }}
                    inputProps={{
                        onBlur: onUsernameBlur
                    }} />
                <Collapse in={!!username.alerts}>
                    <Paper variant="outlined" sx={{ pt: 1, pb: 1, bgcolor: 'warning.light' }}>
                        <AlertList alerts={username.alerts} />
                    </Paper>
                </Collapse>
            </Collapse>

            <Collapse in={showPasswordField()}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPasswordText() ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password.value}
                    onChange={onPasswordChange}
                    onKeyDown={async (ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            await onContinue();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={onShowPasswordClick}>{showPasswordText() ? <VisibilityOff /> : <Visibility />}</IconButton>
                            </InputAdornment>
                        )
                    }} />
                <Collapse in={!!password.alerts}>
                    <Paper variant="outlined" sx={{ pt: 1, pb: 1, bgcolor: 'warning.light' }}>
                        <AlertList alerts={password.alerts} />
                    </Paper>
                </Collapse>
                
            </Collapse>

            <Paper variant="outlined" sx={{ pt: 1, pb: 1 }} ref={captchaElementRef}>

            </Paper>

            <Button onClick={async (ev) => { ev.preventDefault(); await onContinue(); }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!canContinue()} >
                Continue
            </Button>

            <Link onClick={onForget} variant="body2" component="button">
                Forgot password?
            </Link>

            <Collapse in={connection === null || connection === databaseConnection}>
                <Link onClick={onSignUp} variant="body2" component="button">
                    Don't have an account? Sign Up.
                </Link>
            </Collapse>
        </>
    );

    // logic to render external sections
    const externalSections = {
        azuread: () => (<SignInWithAzureAdButton onClick={onContinueAzureAd} />),
    }

    // define the sections to render, order may change based on history
    const sections = [];

    // if we have a last login method saved, add that as the first section
    const lastLoginMethod = Cookies.get('last_login_method') ?? null;
    if (lastLoginMethod && externalSections[lastLoginMethod]) {
        sections.push({ name: 'previous', render: externalSections[lastLoginMethod] });
        delete externalSections[lastLoginMethod];
    }

    // in the middle we always use the internal section
    sections.push({ name: 'internal', render: renderInternalSection });

    // at the end are all the remaining sections
    if (Object.keys(externalSections).length > 0) {
        sections.push({ name: 'external', render: () => (<>{Object.entries(externalSections).map(([name, render]) => <Fragment key={name}>{render()}</Fragment>)}</>) });
    }

    return (
        <>
            <Typography component="h1" variant="h5">
                Sign In
            </Typography>

            <Typography component="h2" variant="h6">
                {config.dict?.signin?.title ?? ""}
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
                {alert && alert.text ? <Alert severity={alert.severity ?? 'info'}> <span>{alert.text.toString()}</span></Alert> : null}

                {sections.map((section, index) => (
                    <Fragment key={section.name}>
                        {section.render()}
                        {index < sections.length - 1 ? <Divider sx={{ pt: 2 }} variant="body4">OR</Divider> : null}
                    </Fragment>
                ))}
            </Box>
        </>
    );
};

const SignUp = ({ options, config, client, webApi, setMode }) => {
    // look up database connection
    let databaseConnection = null;
    if (client && client.strategies && client.strategies[0] && client.strategies[0].connections && client.strategies[0].connections[0]) {
        databaseConnection = client.strategies[0].connections[0].name;
    }

    const [alert, setAlert] = useState(null);
    const [username, setUsername] = useState({ value: '', visible: true, alerts: null });
    const [password, setPassword] = useState({ value: '', visible: false, alerts: null });
    const [password2, setPassword2] = useState({ value: '' });
    const [ready, setReady] = useState(0);

    // render the captcha when available
    const captchaElementRef = useRef(null);
    const captchaElementPrevRef = useRef(null);
    const captchaRef = useRef(null);
    const webApiPrevRef = useRef(null);
    useLayoutEffect(() => {
        if (webApi && captchaElementRef.current) {
            if (webApi !== webApiPrevRef.current || captchaElementRef.current !== captchaElementPrevRef.current) {
                captchaRef.current = webApi.renderCaptcha(captchaElementRef.current);
                webApiPrevRef.current = webApi;
                captchaElementPrevRef.current = captchaElementRef.current;
            }
        }
    }, [webApi, captchaElementRef.current]);

    const setAlertError = (text) => {
        if (text) {
            setAlert({ text: text, severity: 'error' });
        } else {
            setAlert(null);
        }
    };

    const setAlertInfo = (text) => {
        if (text) {
            setAlert({ text: text, severity: 'info' });
        } else {
            setAlert(null);
        }
    };

    const handleError = (error) => {
        if (error.toString()) {
            setAlertError(error.toString());
        }
    };

    const rulesToAlerts = (rules) => {
        return rules && rules.length > 0 ? rules.map(rule => ({
            severity: rule.verified ? 'success' : 'error',
            text: rule.message,
            args: rule.format,
            alerts: rulesToAlerts(rule.items),
        })) : null;
    };

    const handlePasswordStrengthError = (error) => {
        setPassword({
            ...password,
            alerts: rulesToAlerts(error.description.rules)
        });
    };

    const handleWebApiError = (error) => {
        if (error) {
            if (error.name === 'ValidationError' && error.description) {
                setAlertError(error.description);
            } else if (error.name === 'BadRequestError' && error.description) {
                setAlertError(error.description);
            } else if (error.name === 'PasswordStrengthError' && error.code === 'invalid_password') {
                handlePasswordStrengthError(error);
            } else if (error.description) {
                setAlertError(error.description);
            } else if (error.name) {
                setAlertError(error.name);
            }
        }
    };

    const onUsernameChange = (e) => {
        setUsername({ ...username, value: e.target.value, alerts: null })
    };

    const onPasswordChange = (e) => {
        setPassword({ ...password, value: e.target.value, alerts: null })
    };

    const onPassword2Change = (e) => {
        setPassword2({ ...password2, value: e.target.value })
    };

    const showPasswordText = () => {
        return password.visible;
    };

    const onShowPasswordClick = () => {
        setPassword({ ...password, visible: !password.visible });
    }

    const canSignUp = () => {
        if (!username.value) {
            return false;
        }

        if (!password.value) {
            return false;
        }

        if (!password2.value) {
            return false;
        }

        if (password.value != password2.value) {
            return false;
        }

        return true;
    };

    const onSignUp = async () => {

        if (!username.value) {
            setAlertError('Email address required for sign up.')
            return;
        }

        if (!password.value) {
            setAlertError('Password required for sign up.');
            return;
        }

        if (!password2.value) {
            setAlertError('Password required for sign up.');
            return;
        }

        setReady(r => r + 1);

        try {
            webApi.redirect.signupAndLogin({
                connection: databaseConnection,
                email: username.value,
                password: password.value,
                captcha: captchaRef.current?.getValue()
            }, function (error, response) {
                if (error) {
                    handleWebApiError(error);
                }

                setReady(r => r - 1);
            });

        } catch (error) {
            handleError(error);
            setReady(r => r - 1);
        }
    };

    const onSignIn = () => {
        setMode('signin');
    };

    const onContinueAzureAd = (e) => {
        e.preventDefault();

        // save last logon method
        Cookies.set('last_login_method', 'azuread');

        setReady(r => r + 1);

        try {
            webApi.authorize({
                connection: 'azuread',
            }, function (error, response) {
                if (error) {
                    handleWebApiError(error);
                }

                setReady(r => r - 1);
            });

        } catch (err) {
            handleError(err);
            setReady(r => r - 1);
        }
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                {config.extraParams.action === 'linking' ? 'Enroll In MFA - Please enter your current credentials.' : 'Sign Up' }
            </Typography>

            <Typography component="h2" variant="h6">
                {config.extraParams.action === 'linking' ? 'ezdeploy' : 'Create an account to get started with ezdeploy'}
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
                {alert && alert.text ? <Alert severity={alert.severity ?? 'info'}> <span>{alert.text.toString()}</span></Alert> : null}

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    label="Email Address"
                    type="email"
                    autoComplete="email"
                    autoFocus
                    value={username.value}
                    onChange={onUsernameChange}
                    onKeyDown={async (ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            await onSignUp();
                        }
                    }}                />
                <Collapse in={!!username.alerts}>
                    <Paper variant="outlined" sx={{ pt: 1, pb: 1, bgcolor: 'warning.light' }}>
                        <AlertList alerts={username.alerts} />
                    </Paper>
                </Collapse>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPasswordText() ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password.value}
                    onChange={onPasswordChange}
                    onKeyDown={async (ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            await onSignUp();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={onShowPasswordClick}>{showPasswordText() ? <VisibilityOff /> : <Visibility />}</IconButton>
                            </InputAdornment>
                        )
                    }} />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password2"
                    name="password2"
                    label="Password"
                    type={showPasswordText() ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password2.value}
                    onChange={onPassword2Change}
                    onKeyDown={async (ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            await onSignUp();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={onShowPasswordClick}>{showPasswordText() ? <VisibilityOff /> : <Visibility />}</IconButton>
                            </InputAdornment>
                        )
                    }} />

                <Collapse in={!!password.alerts}>
                    <Paper variant="outlined" sx={{ pt: 1, pb: 1, bgcolor: 'warning.light' }}>
                        <AlertList alerts={password.alerts} />
                    </Paper>
                </Collapse>

                <Paper variant="outlined" sx={{ pt: 1, pb: 1 }} ref={captchaElementRef}>

                </Paper>

                {config.extraParams.action === 'linking' ? null :
                    <Link onClick={onSignIn} variant="body2" component="button">
                        Already have an account? Sign In.
                    </Link>
                }

                <Button onClick={async (ev) => { ev.preventDefault(); await onSignUp(); }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!canSignUp()}>
                    Continue
                </Button>

                {config.extraParams.action === 'linking' ? null :
                    <>
                        <Divider sx={{ pt: 2 }} variant="body4">OR</Divider>
                        <SignInWithAzureAdButton onClick={onContinueAzureAd} />
                    </>
                }

            </Box>
        </>
    );
};

const Host = ({ options, config, client, webApi }) => {
    const [mode, setMode] = useState(config.extraParams?.screen_hint ?? 'signin');

    return (
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 5 }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} variant="square" src={config.icon} />
            {mode === 'signin' ? <SignIn options={options} config={config} client={client} webApi={webApi} setMode={setMode} /> : null}
            {mode === 'signup' ? <SignUp options={options} config={config} client={client} webApi={webApi} setMode={setMode} /> : null}
        </Box>
    );
};

const Root = ({ options, config }) => {
    const [ready, setReady] = useState(false);
    const [client, setClient] = useState(null);
    const webApi = useRef(null);
    const [webApiInc, setWebApiInc] = useState(0);
    const theme = useRef(createTheme({}));
    const [themeInc, setThemeInc] = useState(0);

    // refresh theme based on config
    useEffect(() => {
        theme.current = createTheme({
            palette: {
                primary: {
                    main: config.colors?.primary ?? '#000000'
                }
            }
        });

        setThemeInc(themeInc + 1);
    }, [config.colors?.page_background, config.color?.primary]);

    // download client information based on config
    useEffect(async () => {
        try {
            // download client config document, which is a JavaScript fragment that invokes Auth0.setClient({})
            const path = new URL('client/' + config.clientID + ".js", config.clientConfigurationBaseUrl ?? options.auth0_web_url).toString();
            const resp = await fetch(path);
            const code = await resp.text();

            // execute code with Auth0.setClient function that accepts the data
            const func = new Function('Auth0', code);
            func({ setClient: setClient });
        } catch (e) {
            console.error(e);
        }
    }, [config.clientID, config.clientConfigurationBaseUrl, options.auth0_web_url]);

    // generate webApi object based on config
    useEffect(() => {
        webApi.current = new Auth0.WebAuth(Object.assign({
            overrides: {
                __tenant: config.auth0Tenant,
                __token_issuer: config.authorizationServer.issuer
            },
            domain: config.auth0Domain,
            clientID: config.clientID,
            redirectUri: config.callbackURL,
            responseType: 'code'
        }, config.internalOptions));

        setWebApiInc(webApiInc + 1);
    }, [config.auth0Tenant, config.authorizationServer.issuer, config.auth0Domain, config.clientID, config.callbackURL, config.internalOptions])

    // mark page as ready when both are present
    useEffect(() => {
        setReady(theme.current && client && webApi.current);
    }, [client, webApiInc]);

    // options might not be loaded
    if (options === null) {
        return <></>;
    }

    // config might not be loaded
    if (config === null) {
        return <></>;
    }

    return (
        <ThemeProvider theme={theme.current}>
            <CssBaseline />
            <Backdrop open={!ready}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={0}
                direction="column"
                align="center"
                justify="center">
                <Grid item xs={3}>
                    {client && webApi.current ? <Host options={options} config={config} client={client} webApi={webApi.current} /> : null}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

const root = document.getElementById('root');
const options = JSON.parse(root.getAttribute('data-options'));
const config = root.getAttribute('data-config-enc') ? JSON.parse(decodeURIComponent(escape(atob(root.getAttribute('data-config-enc'))))) : JSON.parse(root.getAttribute('data-config'));

const leeway = config.internalOptions.leeway;
if (leeway) {
    const convertedLeeway = parseInt(leeway);
    if (!isNaN(convertedLeeway)) {
        config.internalOptions.leeway = convertedLeeway;
    }
}

ReactDOM.render(<Root options={options} config={config} />, root);
